import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/App/Layout';
import Cart from '../../components/Shop/Cart';
import Container from '@material-ui/core/Container';
import { Box, Paper, Typography } from '@material-ui/core';
import useCart from '../../hooks/Shop/useCart';
import EmptyCart from '../../components/Shop/EmptyCart';

const CartPage = () => {
  const { t } = useTranslation();
  const { status, cart } = useCart();

  return (
    <Layout title={t('Cart')}>
      <Box mt={4} mb={2} px={2}>
        <Container maxWidth="sm">
          {status === 'success' ? (
            cart.lines.count > 0 ? (
              <React.Fragment>
                <Box marginBottom={3}>
                  <Typography variant="h2">{t('Your items')}</Typography>
                  <Typography variant="h6">{t(`${cart.lines.count} items total`)}</Typography>
                </Box>
                <Paper>
                  <Cart cart={cart} toCheckout />
                </Paper>
              </React.Fragment>
            ) : (
              <EmptyCart />
            )
          ) : null}
        </Container>
      </Box>
    </Layout>
  );
};

export default React.memo(CartPage);
