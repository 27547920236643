import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as ILink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import * as routes from '../../../navigation/routes';

import PageTitle from '../../App/PageTitle';
import ProductList from '../ProductList';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const IneligibleStep = () => {
  const { t } = useTranslation();

  const membership = useSelector(({ memberships }) => memberships);

  return (
    <>
      <PageTitle title={t('You are not eligible for membership at this time.')} />
      <Typography>
        {
          'In order for membership, you are required to have an active professional level certification in either the Linux Professional (LPIC) or Open Technology tracks.'
        }
      </Typography>
      <Typography>
        {'Read more about our certifications here: '}
        <Link href="https://lpi.org/certifications">https://lpi.org/certifications</Link>
      </Typography>
      <br />
      <Typography>
        {
          'If you have inactive professional certifications, you may apply to have them reactivated for the purpose of joining by submitting claims for 20 PDUs worth of activity.'
        }
      </Typography>
      <Typography>
        {'Read more about this policy at:'}

        <Link href="https://www.lpi.org/member/pdu-procedures-and-policies#pdus-required-by-holders-of-inactive-certifications">
          https://www.lpi.org/member/pdu-procedures-and-policies#pdus-required-by-holders-of-inactive-certifications
        </Link>
      </Typography>
      <br />
      <Button component={ILink} to={routes.ACTIVITIES_LIST} variant="contained" color="primary">
        {t('Add activities')}
      </Button>

      <Box paddingTop={5}>
        <ProductList products={membership.products} disabled />
      </Box>
    </>
  );
};

export default React.memo(IneligibleStep);
