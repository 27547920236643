import _keyBy from 'lodash/keyBy';
import _keys from 'lodash/keys';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser, getActiveCertifications, getInactiveCertifications, getScoreboard } from '../../store/selectors';
import StatsItem from './StatsItem';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { addMonths, formatDistanceToNow, isBefore } from 'date-fns';
import { useHistory } from 'react-router-dom';

const Stats = () => {
  const history = useHistory();
  const user = useSelector(getUser);
  const score = useSelector(getScoreboard);
  const activeCertifications = useSelector(getActiveCertifications);
  const inactiveCertifications = useSelector(getInactiveCertifications);
  const { t } = useTranslation();

  const cats = _keyBy(score.categories, 'name');

  const subtitle =
    user.membership_step === 'member' ? `Cycle ends on ${new Date(score.cycle_end).toLocaleDateString()}` : '';

  const targetReached = score.pdu >= score.target;
  const educationCompleted = cats['Education'] && cats['Education'].pdu >= cats['Education'].minimum;
  const communityCompleted = cats['Community'] && cats['Community'].pdu >= cats['Community'].minimum;
  const experienceCompleted = cats['Experience'] && cats['Experience'].pdu >= cats['Experience'].minimum;

  return (
    score &&
    _keys(cats).length > 0 && (
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box marginBottom={4}>
            <StatsItem
              label="Membership Status"
              value={
                user.membership_step === 'member'
                  ? 'Active'
                  : user.membership_step === 'expired'
                  ? 'Expired'
                  : user.membership_step === 'renewing'
                  ? 'Renewing'
                  : 'None'
              }
              subtitle={subtitle}
            />
            {(() => {
              const expire = new Date(user.membership_stop);
              if (user.membership_step === 'member' && isBefore(expire, addMonths(new Date(), 3))) {
                return (
                  <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography variant="subtitle1" color="error">
                      {t('Your membership will expire in ')}
                      {formatDistanceToNow(expire)}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => history.push('/memberships')}
                      color="primary"
                      style={{ marginLeft: 5 }}
                    >
                      {t('Renew now')}
                    </Button>
                  </Box>
                );
              }
            })()}
            {user.membership_step === 'expired' && (
              <Box>
                <Link to="/memberships">{t('Renew your membership')}</Link>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box marginBottom={4}>
            {score.cycle_id && user.membership_step === 'member' ? (
              <Box display="flex" justifyContent="space-between">
                <StatsItem
                  label="PDUs"
                  error={!targetReached || !educationCompleted || !communityCompleted || !experienceCompleted}
                  value={score.pdu}
                  comparision={score.target}
                  subtitle="earned/required"
                />

                <StatsItem
                  label="Education"
                  error={!educationCompleted}
                  value={cats['Education'].real_pdu}
                  comparision={cats['Education'].maximum}
                  subtitle="earned/max"
                />

                <StatsItem
                  label="Community"
                  error={!communityCompleted}
                  value={cats['Community'].real_pdu}
                  comparision={cats['Community'].maximum}
                  subtitle="earned/max"
                />

                <StatsItem
                  label="Experience"
                  error={!experienceCompleted}
                  value={cats['Experience'].real_pdu}
                  comparision={cats['Experience'].maximum}
                  subtitle="earned/max"
                />
              </Box>
            ) : (
              user.membership_step !== 'member' &&
              inactiveCertifications.length > 0 &&
              activeCertifications.length === 0 && (
                <Box display="flex" justifyContent="space-between">
                  <StatsItem
                    label="Available PDUs"
                    value={score.remaining_pdu}
                    comparision={score.target / 3}
                    subtitle="earned/required"
                  />
                </Box>
              )
            )}
          </Box>
        </Grid>
      </Grid>
    )
  );
};

export default React.memo(Stats);
