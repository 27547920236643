import _values from 'lodash/values';
import React from 'react';
import { Box } from '@material-ui/core';

import Layout from '../../components/App/Layout';
import ProgramsGrid from '../../components/Volunteers/ProgramsGrid';
import { usePrograms } from '../../hooks/Volunteers';
import LoadingState from '../../components/Common/LoadingState';
import EmptyState from '../../components/Common/EmptyState';

const ProgramsPage = () => {
  const { programs, isLoading } = usePrograms();

  return (
    <Layout title={'Program Opportunities'}>
      <Box mt={4} px={2}>
        {/* <ProgramsFilters controller={filters} /> */}
        {isLoading ? <LoadingState /> : <ProgramsGrid programs={programs} />}
        {!programs && !isLoading && <EmptyState />}
      </Box>
    </Layout>
  );
};

export default React.memo(ProgramsPage);
